<template>
  <div id="books_index">
    <div class="title" style="margin-left: 2rem">
      <p class="aqt_lang_mn titlefont">{{ "ᠨᠤᠮ ᠦᠨ ᠭᠠᠷᠴᠠᠭ" | U2M }}</p>
    </div>
    <p v-if="isLoading && !infosData.length" class="noData aqt_lang_mn titlefont">
      {{ "ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ" | U2M }}
    </p>

    <div
        class="aqt_zhengzhuang"
        v-for="(item, index) in infosData"
        :key="index">
      <div class="indexList">
        <i class="iconfont iconzishu"></i>
        <p class="aqt_lang_mn contentfont">
          {{ item.titlelang.mn | U2M }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      getInfo: false,
      infosData: [],
      data: {
        directory_id: "",
        book_id: "",
        type: "1",
      },
      content_id: null,
      indexList: [],
      showList: false,
    };
  },
  methods: {
    /*****************************************************************
     *
     * 获取详情数据
     *
     *****************************************************************/
    infoData() {
      const _this = this;
      _this.showToastMask(true);
      let cond = {
        annexboolean: _this.annexboolean,
        book_id: _this.$route.query.id,
        type: 1
      }
      _this.isLoading = false
      _this.$http.get(`api/web/v2/BookDirectory/index`, {params: cond})
          .then((val) => {
            _this.infosData = val.data;
            localStorage.setItem("youmuBook_index", JSON.stringify(_this.infosData));
            _this.showToastMask(false);
            _this.isLoading = true
          }).catch((err) => {
        _this.isLoading = true
        _this.showToastMask(false);
      });
    },
    getBookFromLocal() {
      let book = JSON.parse(localStorage.getItem("youmuBook_index"));
      this.infosData = book;
      this.isLoading = true;
    },
    /*****************************************************************
     *
     * 获取详情数据
     *
     *****************************************************************/
    getPageList(item, index) {
      const _this = this;
      if (!JSON.parse(localStorage.getItem("userInfoMemberId"))) {
        _this.$router.push({
          path: "/login",
          query: {
            lastPath: _this.$route.fullPath,
          },
        });
        return;
      }
      _this.showToastMask(true);
      let cond = {
        directory_id: item.directory_id,
        book_id: item.book_id,
        type: 1
      }
      _this.$http.get("api/web/v1/BookContent/index", {params: cond})
          .then((val) => {
            localStorage.setItem("book_pages", JSON.stringify(val.data));
            _this.indexList = val.data;
            if (!_this.content_id && _this.indexList.length) {
              _this.content_id = _this.indexList[0].content_id;
            }
            if (val.data.length) {
              _this.$router.push({
                path: "/LiteratureContent",
                query: {
                  content_id: _this.content_id,
                  pdfurl: item.original,
                },
              });
            } else {
              if (item.original) {
                _this.$router.push({
                  path: "/PdfView",
                  query: {
                    url: item.original,
                  },
                });
              }
            }
            sessionStorage.setItem('pdfOpen', true)
            _this.showToastMask(false);
          }).catch((err) => {
        _this.showToastMask(false);
      });
    },
    /*****************************************************************
     *
     * 跳转到目录对应内容
     *
     *****************************************************************/
    goBookInfo(item) {
    },
  },

  created() {
    if (localStorage.getItem("youmuBook_index")) {
      this.getBookFromLocal();
    } else {
      this.showToastMask(true);
      setTimeout(() => {
        if (!this.getInfo) {
          this.infoData()
        }
      }, 1000)
    }
    let have_Book = this.$store.state.books.some((item, index) => {
      return item.book_id == this.$route.query.id;
    });
    let index = this.$store.state.books.findIndex((item) => {
      if (item.book_id == this.$route.query.id) {
        return true;
      }
    });
    if (have_Book) {
      this.content_id = this.$store.state.books[index].content_id;
    }
  },
  computed: {
    // 是否显示资源
    annexboolean() {
      return this.$store.getters.annexboolean
    },
  },
  watch: {
    '$store.getters.annexboolean': function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getInfo = true
        this.infoData();
      }
    },
  },
};
</script>

<style lang="less">
#books_index {
  display: flex;
  position: relative;
  overflow: auto !important;
  padding: 0 15px;
  .noData {
    color: #666;
    display: block;
    margin-left: 120px;
    font-size: 2rem;
  }

  .title {
    width: 3rem;
    height: calc(100% - 1rem);
    color: var(--themeColor);
    font-size: 2rem;
    margin: 0;

    p {
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 auto;
      height: 100%;
    }
  }

  .aqt_zhengzhuang {
    height: calc(100% - 3.4rem);
    min-width: 3rem;
    color: #333;
    font-size: 1.2rem;
    margin: 0 0.3rem;
    display: flex;

    i {
      color: var(--themeColor);
      display: block;
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }

    // white-space:nowrap;
    // -o-text-overflow:ellipsis;
    // text-overflow:ellipsis;
    // overflow: hidden;
    img {
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1.8rem;
      word-wrap: break-word;
      word-break: normal;
      max-height: 90%;
      color: #888888;
    }
  }

  .list {
    background-color: var(--themeColor);
    border-radius: 1rem;
    z-index: 9999;
    // width: 0;
    height: 4rem;
    line-height: 4rem;
    position: absolute;

    span {
      margin: 1rem;
      font-size: 1.8rem;
      color: #fff;
    }
  }
}
</style>
